import {connect} from 'react-redux';
import ProjActContainer from './ProjActContainer';
const ProjectsActivator = ({activeProjects,unActiveProjects}) => {
    return (
        <div className='project_activator-container'>
            <h1>Projects Status</h1>
            <div>
                <h4>Active</h4>
                <div className='proj_type-container '>
                    {activeProjects.map((proj,index)=><ProjActContainer classes='active' key={index} projectId={proj._id}/>)}
                </div>
            </div>
            <div>
                <h4>Un Active</h4>
                <div className='proj_type-container '>
                    {unActiveProjects.map((proj,index)=><ProjActContainer classes='unactive' key={index} projectId={proj._id}/>)}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        activeProjects: state.userInfo.project.filter(proj=>proj.isActive),
        unActiveProjects: state.userInfo.project.filter(proj=>!(proj.isActive)),
      };
};
const mapDispatchToProps = (dispatch) => {
    return {
    
    };
};
export default connect(mapStateToProps,mapDispatchToProps)(ProjectsActivator);