import {Chart} from "..";
import ProjectsActivator from './ProjectsActivator';
const PanelPage=({allProjects})=>{
    return (
        <div className="projectsHome">
            <h1>Projects Progress Bar</h1>
            <Chart/>
            <ProjectsActivator/>
        </div>
    )
}

export default PanelPage;