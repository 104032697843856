import { useState,useEffect } from "react";
import {Form,Add,Finance,Notifications,Workout,SchoolSchedule,PageSlider} from "../components";
import {connect} from 'react-redux'

const presonalPages=({weeklySchedule,expense,workoutPlan,notification})=>(
       [
            {
                name:'Finance',
                heading:'Economically Managed',
                component:()=>
                    <>
                        
                        { Array.isArray(expense) && expense.length>0 ? 
                                <Finance/>
                            :
                            <div className="personal_subject-container container-boxShadow">
                                <h1>Add Finance</h1>
                            </div>
                        }
                    </>
                ,
            },
            {
                name:'Schedule',
                heading:'Weekly Schedule',
                component:()=>
                    <>
                        
                        {Array.isArray(weeklySchedule) && weeklySchedule.length>0 ? 
                            <SchoolSchedule/> 
                            // <></>
                            :
                            <div className="personal_subject-container container-boxShadow">
                                <h1>Add Scheduler</h1>
                            </div>
                        }
                    </>
                ,
            },
            
            {
                name:'Life',
                heading:'Day To Day',
                component:()=>
                    <div className="even-autoHeight flex-even ">
                        <div className="personal_subject-container container-boxShadow fixed_height">
                            <h1> Workout</h1>
                            { Array.isArray(workoutPlan) && workoutPlan.length>0 ? 
                                        <Workout/>
                                    
                                    :
                                    <div>
                                        <h1>Add Workout</h1>
                                    </div>
                            }
                        </div>
                        <div className="personal_subject-container container-boxShadow fixed_height">
                            <h1>notifications</h1>
                            { Array.isArray(notification) && notification.length>0 ? 
                                        <Notifications/>
                                    :
                                    <div>
                                        <h1>Add notifications</h1>
                                    </div>
                            }
                            
                        </div>
                    </div>
                ,
            },
            
        ]
    )
const Personal =({userInfo})=>{
    const [formParams,setFormParams]=useState(false);
    return (
    <div className="page-container">
        <h1>איזור אישי </h1>
        <PageSlider  pages={presonalPages(userInfo)}/>
        <Add route='personal' setFormMethod={setFormParams}/>
        {formParams!==false?  <Form dbtype={formParams[0]} method={formParams[1]} setFormMethod={setFormParams}/> : <></>}
    </div>
    )
}
const mapStateToProps = (state) => {
    return {
        userInfo: state.userInfo,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
     
    };
  };
export default connect(mapStateToProps,mapDispatchToProps)(Personal);