import React, { useState, useEffect } from 'react';
import {Form,Add} from "../components";
import {connect} from 'react-redux';
import { PageSlider } from '../components'
import ProjectPage from '../components/projects/ProjectPage';
import PanelPage from '../components/projects/PanelPage';
import HomeIcon from '@mui/icons-material/Home';

const getProjectsPages=(projects)=>{
    let pages=projects.map((project,index)=>{
        return {
                name:project.name,
                heading:project.name,
                component:()=><ProjectPage key={index} projectId={project._id}/>,
            }
    })
    const homePage={
        name:{icon:()=><HomeIcon/>},
        heading:`Panel`,
        component:()=><PanelPage allProjects={projects}/>,
    }
    pages.unshift(homePage);
    return pages;
}

const Projects =({projects,userInfo})=>{
    const [formParams,setFormParams]=useState(false);
    const [projectsPages,setProjectPages]=useState(getProjectsPages(projects));
    useEffect(()=>setProjectPages(getProjectsPages(projects)),[projects])
    return (
    <div className="page-container projects">
        <PageSlider  pages={projectsPages}/>
        <Add route='projects' setFormMethod={setFormParams}/>
        {formParams!==false?  <Form dbtype={formParams[0]} method={formParams[1]} setFormMethod={setFormParams}/> : <></>}
    </div>
    )
}
const mapStateToProps = (state) => {
    return {
        projects: state.userInfo.project.filter(proj=>proj.isActive),
        userInfo:state.userInfo,
      };
    };
    const mapDispatchToProps = (dispatch) => {
      return {
       
      };
    };
export default connect(mapStateToProps,mapDispatchToProps)(Projects);