import {useState,useEffect} from 'react';
import {Switch} from '@mui/material';
import {connect} from 'react-redux';
import {setValueByKeyAndId} from '../../../redux/actions/userInfoActions';
import api from '../../../api';
const ProjActContainer = ({projectId,project,updateProjectStatus,classes}) => {
    const [checked, setChecked] = useState(project.isActive);
    const handleSwitch=  (e) => {
        setChecked(e.target.checked);
        updateBackend(e.target.checked);
    }
    const updateBackend=async(value)=>{
        try{
           const response=await api.post('project','update-active',{projectId,isActive:value});
           if(response.status===200){
                updateProjectStatus(projectId,{isActive:value});
           }
           else{
                console.log('NOT Updated BAD RESPONSE ');
                setChecked(!value);
           }
        }
        catch(err){
            console.log(err);
            setChecked(!value);
        }
    }
    useEffect(()=>{
        setChecked(project.isActive)
    },[projectId])
    return (
        <div className={`proj_act-container container-boxShadow ${classes && classes}`}>
            <h6>{project.name}</h6>
            <Switch color='success'  checked={checked} name='isActive' onChange={handleSwitch}/>
        </div>
    );
};

const mapStateToProps = (state,ownProps) => {
    return {
        project:state.userInfo.project.find(currentProject=>currentProject._id===ownProps.projectId)
      };
};
const mapDispatchToProps = (dispatch) => {
    return {
        updateProjectStatus:(id,updatedKeys)=>dispatch(setValueByKeyAndId('project',id,updatedKeys))
    };
};
export default connect(mapStateToProps,mapDispatchToProps)(ProjActContainer);