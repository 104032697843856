import { useEffect,useState } from 'react';
import {BasicTable} from '../';

function extractSpecificKeys(objectsArray, keys) {
    return objectsArray.map(object => {
      const newObj = {};
      keys.forEach(key => {
        if (object.hasOwnProperty(key)) {
          newObj[key] = object[key];
        }
      });
      return newObj;
    });
  }
  function capitalizeFirstCharOfWords(str) {
    return str.split(' ') // Split the string into words
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter and concatenate with the rest
      .join(' '); // Join the words back into a single string
  }
const Incomes = ({incomes,sumMoney=''}) => {
    const headCells = [
        {
          key:'name',
          label: 'Name',
        },
        {
          key: 'averagePaycheck',
          label: 'Average Paycheck',
        },
        {
          key: 'coin',
          label: 'Coin',
        },
      ];
      
      const rows=extractSpecificKeys(incomes,['name','averagePaycheck','coin']);
      const [tableData,setTableData]=useState({headCells,dataList:rows,header:`${incomes[0].hasOwnProperty('type') ? capitalizeFirstCharOfWords(incomes[0].type) : 'All'}:${sumMoney}`});
      useEffect(()=>{
        const rows=extractSpecificKeys(incomes,['name','averagePaycheck','coin']);
        setTableData({...tableData,dataList:rows})
      },[incomes])

    return (
            <BasicTable tableData={tableData}/>
    );
};

export default Incomes;