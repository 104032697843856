import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import { useEffect } from 'react';
import Incomes from './Incomes';
import BankInfo from './BankInfo';
import Expenses from './Expenses';
import FinancialConduct from './FinancialConduct';
import PageSlider from '../PageSlider';
import {BarsChart} from '../';
import store from '../../store';

const sumMoneyByType_andCurrency=(arr,moneyKey,currenciesRatio,chosenCurrency='shekel')=>{
  let sumMoney={};
  arr.forEach(subArr => {
    const initSun=0;
    const TypeSum=subArr.reduce((acc,obj)=> acc + ( obj[moneyKey] * currenciesRatio[obj['coin']][chosenCurrency] )
    ,initSun);
    sumMoney[subArr[0].type]=TypeSum;
  });
  return sumMoney;
}

const costsPerMonths_monthlyPeriod=(arr,currenciesRatio,chosenCurrency='shekel',numberOfMonth=6)=>{
  const month=(new Date()).getMonth();
  const costs = Array(numberOfMonth).fill(0);
  function isInMonth(monthToCheck, expense) {
    const { startMonth, interval } = expense;
    const difference = monthToCheck - startMonth;
    return difference >= 0 && difference % interval === 0;
  }
  arr.forEach(exp=>{
    for(let i=0;i<costs.length;i++){
      if(exp.interval===1 || isInMonth(i+month,exp)){
        costs[i]+=exp.cost * currenciesRatio[exp['coin']][chosenCurrency];
      }
    }
  })
  return costs;

}

const costsPerMonths_weeklyPeriod=(arr,currenciesRatio,chosenCurrency='shekel',numberOfMonth=6)=>{
  const costs = Array(numberOfMonth).fill(0);
 
  arr.forEach(exp=>{
    for(let i=0;i<costs.length;i++){
        const timesPerMonth=4/exp.interval
        costs[i]+=(exp.cost*timesPerMonth)* currenciesRatio[exp['coin']][chosenCurrency];
    }
  })
  return costs;

}
const getMonthsExpenses=(expenses,currenciesRatio,chosenCurrency='shekel',numberOfMonth=6)=>{
  const expGroupBy_paymentPeriod=store.groupByKey(expenses,'paymentPeriod');
  const monthlyCosts=costsPerMonths_monthlyPeriod(expGroupBy_paymentPeriod['monthly'],currenciesRatio);
  const weeklyCosts=costsPerMonths_weeklyPeriod(expGroupBy_paymentPeriod['weekly'],currenciesRatio);
  const costs = Array(numberOfMonth).fill(0);
  for(let i=0;i<costs.length;i++){
    costs[i]+=monthlyCosts[i]+weeklyCosts[i];
  }
  return costs;
}
const bankPages=(banks,incomes,expenses,cards,currenciesRatio)=>{

    let bankPages=[];
    for(const i in banks){
      bankPages.push({
        heading:'Incomes & Expenses & Investments'
      });
      const filteredCardsIds=Array.isArray(cards) && cards.length>0 ? cards.filter(val=>val.bankId==banks[i]._id).map(card=>card['_id']) : [];
      
      const bankIncomes=Array.isArray(incomes) && incomes.length>0 ? (incomes.filter(val=>val.bankId==banks[i]._id)): [];
      const bankExpenses=Array.isArray(expenses) && expenses.length>0 ? (expenses.filter(val=>filteredCardsIds.includes(val.cardId))) : [];
      const bankIncByType=Object.values(store.groupByKey(bankIncomes,'type'));
      const bankExpByType=Object.values(store.groupByKey(bankExpenses,'type'));
      const splitEvenInc=store.splitArrays(bankIncByType);
      const splitEvenExp=store.splitArrays(bankExpByType);
      const IncSumByType=sumMoneyByType_andCurrency(bankIncByType,'averagePaycheck',currenciesRatio);
      const ExpSumByType=sumMoneyByType_andCurrency(bankExpByType,'cost',currenciesRatio);
      const sumIncomes=Number((Object.values(IncSumByType).reduce((acc, num) => acc + num, 0)).toFixed(2));
      const sumExpenses=Number((Object.values(ExpSumByType).reduce((acc, num) => acc + num, 0)).toFixed(2));
      const spareMoney=sumIncomes-sumExpenses;
      const expPercentage=Number((sumExpenses/sumIncomes).toFixed(2));
      // change to sum of last 12 month + projects and shit
      const sumInExByHomeCoinData=store.getIncomeExpensesReport_byCurrency('shekel',bankIncomes,bankExpenses,currenciesRatio);
      const averageLastYearlyIncome=12*sumInExByHomeCoinData.income/12;
      let rechart6NextMonths=store.rechartGetNext6Months((new Date()).getMonth());
      const monthsExpenses=getMonthsExpenses(bankExpenses,currenciesRatio);
      const rechartFinanceData=rechart6NextMonths.map((rechartDataObj,index)=>({...rechartDataObj,Incomes:sumIncomes,Expenses:monthsExpenses[index],Investments:sumInExByHomeCoinData.investment}));
      const barsColors={Incomes:'#82ca9d',Expenses:'rgba(255, 165, 0)',Investments:'rgb(0, 128, 255)'};
      bankPages[i]['name']=banks[i].name;
      bankPages[i]['component']=()=>
          <div>
            <FinancialConduct/>
            <BankInfo currenciesRatio={currenciesRatio} bank={banks[i]} sumIncomes={bankIncomes} sumExpenses={bankExpenses}/>
            <div style={{width:'100%',height:'500px'}} className='finance chart-container'>
              <BarsChart data={rechartFinanceData} chosenColors={barsColors}/>
            </div>
              <div className="">
                <div className='spare_money-container'>
                  <h1 className='spare_money'>Spare Money After Expenses (Limit On Credit Card)(need to add saving,kniot,gym) <span className={spareMoney < 3000 ? `bad` : `good`}>{spareMoney.toFixed(2)}</span>₪</h1>
                  <h1 className='spare_money'>This Month Expenses <span className={'bad_but_not_that'}>{(monthsExpenses[0]).toFixed(2)}</span>₪</h1>
                  <h1 className='spare_money'>Expenses Percentage <span className={expPercentage > 0.5 ? `bad` : `good`}>{expPercentage}</span>%</h1>
                </div>
                <div>
                  <h1 className='finance-category_header'>Incomes : {sumIncomes}</h1>
                  <div className='multi_bt-container'>
                  {splitEvenInc.map((incGroup,index)=>
                      <div key={index} className='bt_column-contaier'>
                        {incGroup.map((incType,index)=><Incomes key={index} incomes={incType} sumMoney={(IncSumByType[incType[0].type]).toFixed(2)}/>)}
                      </div>
                    )}
                </div>
                </div>
                <div>
                  <h1 className='finance-category_header'>Sum Expenses : {sumExpenses}</h1>
                  <div className='multi_bt-container'>
                    {splitEvenExp.map((expGroup,index)=>
                      <div key={index} className='bt_column-contaier'>
                        {expGroup.map((expType,index)=><Expenses key={index} expenses={expType} sumMoney={(ExpSumByType[expType[0].type]).toFixed(2)}/>)}
                      </div>
                    )}
                  </div>
                </div>
                
              </div>
          </div>
      bankPages[i]['subHeader']=`Average Income Last 12 Month :${averageLastYearlyIncome.toFixed(0)}₪  (Needs Update)`;
    }
    return bankPages;
}
const Finance = ({ cards, expenses , month , banks, incomes,paycheck,currenciesRatio }) => {
  const classes='container-boxShadow finance-slider';
  useEffect(()=>{},[banks,cards,expenses]);
    return (
      <div className='finance-container'>
      
        { Array.isArray(banks) && banks.length>0 ? 
            <PageSlider classes={classes} pages={bankPages(banks,incomes,expenses,cards,currenciesRatio)}/>
            
          :
          <div>
              <h1>Add Bank Account</h1>
          </div>
          }
      
    </div>
    );
  };
  
  
const mapStateToProps = (state) => {
    return {
      banks:state.userInfo.bank,
      cards: state.userInfo.card,
      expenses:state.userInfo.expense,
      incomes:state.userInfo.income,
      paychecks:state.userInfo.paycheck,
      currenciesRatio:state.userInfo.currenciesRatio,
      month:(moment().month()+1).toString().padStart(2,'0')
    };
  };
export default connect(mapStateToProps)(Finance);